import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import images from '../../assets/images';
import { goToMenuScene } from '../../redux/actions/activeSceneActions';
import { hideRaffle } from '../../redux/actions/raffleActions';

function HomeButton() {
  const dispatch = useDispatch();
  const isRaffleActive = useSelector((state) => state.isRaffleActive);
  const onHomeButtonClick = () => {
    if (isRaffleActive) {
      dispatch(hideRaffle());
    }
    dispatch(goToMenuScene());
  };

  return (
    <div onClick={() => onHomeButtonClick()} className="home-button">
      <img src={images.homeIcon} className="home-image" />
    </div>
  );
}

export default HomeButton;
