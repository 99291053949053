export function forMap(iterationCount, cb) {
  forMap.break = '#34#null#break#hdk89';

  const arr = [];
  if (typeof iterationCount !== 'number' || isNaN(iterationCount)) {
    throw new TypeError('first arg should be a number');
  }
  if (typeof cb !== 'function') {
    throw new TypeError(cb + ' is not a function');
  }

  for (let index = 0; index < iterationCount; index++) {
    const val = cb(index, iterationCount);
    if (val === forMap.break) {
      break;
    }
    arr.push(val);
  }
  return arr;
}

export function isDevelopmentStage() {
  return process.env.NODE_ENV === 'development';
}

export function addKey(object, key) {
  object[key] = null;
  return object;
}
export function getCorrectTimeTillDraw (time) {
  if(time > 59) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }else{
    return `00:${time < 10 ? `0${time}` : time}`
  }
}

export function getBeforeAnimationTime (gameType) {
  return gameType === 1 ? `00:59` : `02:30`
}
/**
 *  old reducer example:                                new reducer example:
 *  function counterReducer(state = 0, action) {	 	const counterReducer = createReducer(0, (state, action)=>{
		switch (action.type) {                      		return {
			case 'INCREMENT':                       			'INCREMENT': state + 1,
				return state + 1;                   			'DECREMENT': state - 1,
			case 'DECREMENT':                       			'SOME_FUNCTIONALITY' : ()=> {
				return state - 1;                   				//do some functionality here
			case 'SOME_FUNCTIONALITY' :             				return 'something';
				//do something here                 			}
				return 'something';                 		}
			default:                                	});
				return state
		}
	}
 */
export function createReducer(initialState, cb) {
  return function (state = initialState, action) {
    const switchableObject = cb(state, action);
    if (switchableObject.hasOwnProperty(action.type)) {
      if (typeof switchableObject[action.type] === 'function') {
        return switchableObject[action.type]();
      }
      return switchableObject[action.type];
    }
    return state;
  };
}

export function createAction(type, haveValue = false) {
  return haveValue
    ? value => ({type, value})
    : {type};
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}