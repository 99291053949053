import store from '../redux/store';
import { addKey, forMap } from './general';
import { setError } from '../redux/actions/errorActions';
import { formatFrequencyBalls } from './game';

const ticketStatusInfo = {
  0: 'pending',
  1: 'returned',
  2: 'won',
  3: 'lost',
};

function dataParser(data) {
  const {
    currency,
    userId,
    userBalance,
    ballFrequency,
    drawInfo,
    lastRaces,
    maximalBet,
    minimalBet,
    maxWin,
    secondsTillDraw,
    stakeDenomination,
    userStakes,
    usersCurrentDrawStakes,
    amountRanges,
  } = data;

  const parsedData = {};

  if (data.error) {
    console.log(data.note);
    return store.dispatch(setError(data.message));
  }

  if (!!ballFrequency) {
    parsedData.ballFrequency = {
      activeTab: Object.keys(ballFrequency)[0],
      balls: formatFrequencyBalls(ballFrequency),
    };
  }

  // if (!!ballFrequency) {
  //   parsedData.ballFrequency = {
  //     coldBalls: ballFrequency.coldBalls.reduce((acc, el) => {
  //       acc[el[0]] = el[1];
  //       return acc;
  //     }, {}),
  //     hotBalls: ballFrequency.hotBalls.reduce((acc, el) => {
  //       acc[el[0]] = el[1];
  //       return acc;
  //     }, {}),
  //   };
  // }

  if (!!lastRaces) {
    parsedData.drawHistoryTickets = lastRaces.map((ticket) => ({
      id: ticket.id,
      dt: ticket.dt,
      digits: ticket.r.split(','),
    }));
  }

  if (!!userStakes) {
    parsedData.userHistoryTickets = userStakes.reduce((acc, ticket) => {
      const { id } = ticket;
      const drawId = ticket.draw;
      const digits = ticket.bet.split(',');
      const betMoney = ticket.sum;
      const date = ticket.dt;
      const winCombination = !!ticket.r
        ? ticket.r.split(',').reduce((acc, el) => addKey(acc, el), {})
        : {};
      const wonMoney = ticket.wsum;
      const ticketStatus = ticketStatusInfo[ticket.ststatus];
      const currency = ticket.currency;

      const newTicket = {
        digits,
        id,
        drawId,
        betMoney,
        date,
        winCombination,
        wonMoney,
        ticketStatus,
        currency,
      };
      (acc[drawId] = acc[drawId] || []).push(newTicket);
      return acc;
    }, {});
  }

  if (!!usersCurrentDrawStakes) {
    parsedData.userCurrentTickets = usersCurrentDrawStakes.reduce((acc, ticket) => {
      const newTicket = {
        digits: ticket.bet.split(','),
        date: ticket.dt,
        id: ticket.id,
        betMoney: ticket.sum,
        winCombination: ticket.r,
        ticketStatus: ticketStatusInfo[ticket.ststatus],
        wonMoney: ticket.wsum,
      };
      acc.push(newTicket);
      return acc;
    }, []);
  }

  if (!!maximalBet && !!minimalBet && !!stakeDenomination && !!maxWin) {
    parsedData.betAmountOption = {
      maxBet: Number(maximalBet),
      minBet: Number(minimalBet),
      betStep: Number(stakeDenomination),
      maxWin: Number(maxWin),
    };
  }

  if (!!secondsTillDraw) {
    parsedData.secTillDraw = secondsTillDraw;
  }

  if (!!drawInfo) {
    parsedData.drawInfo = drawInfo;
  }

  if (!!userId) {
    parsedData.userData = {
      userId: userId,
      userBalance: Number(userBalance),
    };
  }

  if (!!currency) {
    parsedData.currency = currency;
  }

  if (!!amountRanges) {
    parsedData.amountRanges = amountRanges;
  }

  return parsedData;
}

export default dataParser;
