import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getKenoType } from '../../helpers/game';
import { useTimer } from '../../hooks/useTimer';
import { getSecondTillDraw, setSecTillDraw } from '../../redux/actions/dataActions';

function MenuTimer({ time }) {
  const dispatch = useDispatch();
  const [timerText, setTimerText] = useState('');
  const { timerCount, startTimer, stopTimer } = useTimer(time, 1000, true);

  useEffect(() => {
    startTimer();
    return stopTimer;
  }, [time]);

  useEffect(() => {
    setTimerText(createTimerText(timerCount));
    if (timerCount <= 0) {
      stopTimer();
      dispatch(setSecTillDraw(null));
      dispatch(getSecondTillDraw(getKenoType()));
    }
  }, [timerCount]);

  return <div className="welcome__time">{!!timerCount ? timerText : ':'}</div>;
}

export function createTimerText(sec) {
  let timerInSeconds = sec;
  const minutes = '0' + Math.floor(timerInSeconds / 60);
  const seconds = '0' + Math.floor(timerInSeconds % 60);
  return `${minutes.slice(-2)} : ${seconds.slice(-2)}`;
}

export default MenuTimer;
