import {AMOUNT_TYPES} from '../actionTypes';

const {SET_SELECTED_AMOUNT, PLUS_AMOUNT} = AMOUNT_TYPES;

export function setSelectedAmount(amount) {
  return {type: SET_SELECTED_AMOUNT, payload: amount};
}

export function plusAmount(amountOption) {
  return {type: PLUS_AMOUNT, payload: amountOption};
}