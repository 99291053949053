import ballDrop from './BallDrop.wav';
import addReduceAmount from './add_reduce_amount.wav';
import select from './Select.wav';
import win from './win.wav';
import bet from './BET.wav';
import stars from './Star_Sound.wav';

const sounds = {
  ballDrop,
  addReduceAmount,
  select,
  win,
  bet,
  stars,
};

export default sounds;
