import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import DrawHistoryCurrentTicket from './DrawHistoryCurrentTicket';

function DrawHistory({ drawHistoryTickets }) {
  const isRaffleActive = useSelector((state) => state.isRaffleActive);
  const [newDrawHitroryArr, setNewDrawHitroryArr] = useState([]);

  useEffect(() => {
    const filteredDrawHistoryArr = isRaffleActive
      ? drawHistoryTickets.filter((item, index, arr) => item !== arr[0])
      : drawHistoryTickets;
    setNewDrawHitroryArr(filteredDrawHistoryArr);
  }, [isRaffleActive, drawHistoryTickets]);

  return (
    <li className="game-grid__info-container active">
      <ul className="current-tickets current-tickets--draw-history">
        {newDrawHitroryArr.map((ticket, index) => (
          <DrawHistoryCurrentTicket
            key={index}
            id={ticket.id}
            digits={ticket.digits}
            dt={ticket.dt}
          />
        ))}
      </ul>
    </li>
  );
}

const mapStateToProps = ({ drawHistoryReducer }) => ({
  drawHistoryTickets: drawHistoryReducer,
});

export default connect(mapStateToProps)(DrawHistory);
