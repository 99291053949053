import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GameContainerLeft from '../GameContainerLeft/GameContainerLeft';
import GameContainerRight from '../GameContainerRight/GameContainerRight';
import { getDrawResults, getGameData, getUserData } from '../../redux/actions/dataActions';
import { getKenoType } from '../../helpers/game';
import { showRaffle } from '../../redux/actions/raffleActions';
import { setLanguage } from '../../redux/actions/userDataActions';
import { setError } from '../../redux/actions/errorActions';
import { setFrontSecTillDraw } from '../../redux/actions/frontSecTillDrawAction';

const GameScene = ({
  secTillDraw,
  showRaffle,
  getGameData,
  getUserData,
  setFrontSecTillDraw,
  setLanguage,
  getDrawResults,
  setError,
}) => {
  useEffect(() => {
    getGameData(getKenoType());
    getUserData(getKenoType());
    const language = localStorage.getItem('fallbackLanguage');
    setLanguage(language);
  }, []);

  useEffect(() => {
    const timerInterval = requestTimer(secTillDraw);

    return () => clearInterval(timerInterval);
  }, [secTillDraw]);

  return (
    <main className="game-grid">
      <div className="container">
        <GameContainerLeft />
        <GameContainerRight />
      </div>
    </main>
  );

  function requestTimer(sec) {
    let timerInSeconds = sec;
    const _timerCountDown = () => {
      setFrontSecTillDraw(timerInSeconds);
      timerInSeconds--;
      if (timerInSeconds === 1) {
        getDrawResults(getKenoType()).then(() => {
          showRaffle();
        });
      }
      if (timerInSeconds < 0) {
        setError('Error. Low internet connection.');
      }
    };
    return setInterval(() => _timerCountDown(), 1000);
  }
};

const mapStateToProps = ({ secTillDrawReducer }) => ({
  secTillDraw: secTillDrawReducer,
});

const mapDispatchToProps = {
  setFrontSecTillDraw,
  getGameData,
  getUserData,
  showRaffle,
  setLanguage,
  getDrawResults,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameScene);
