import React from 'react';
import HeaderInfoButton from './HeaderInfoButton';
import UserData from './UserData/UserData';
import Popup from '../Popup/Popup';
import LeaderBoardPopup from '../Popup/LeaderBoardPopup';
import SoundButton from './SoundButton';
import { SCENE_NAMES } from '../../constants/game';
import { useSelector } from 'react-redux';
import HomeButton from './HomeButton';

const { GAME_SCENE } = SCENE_NAMES;

const Header = () => {
  const activeScene = useSelector((state) => state.activeScene);
  const isHomeButtonAvtive = useSelector((state) => state.isHomeButtonAvtive);

  return (
    <header className="header">
      <div className="container">
        {activeScene === GAME_SCENE && isHomeButtonAvtive && <HomeButton />}
        <Popup />
        <LeaderBoardPopup />
        <UserData />
        <div className="header__container right-width header__container--right">
          <ul className="game-settings__list">
            <SoundButton />
            <HeaderInfoButton />
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
