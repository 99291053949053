import {CHANGE_SCENE} from "../actionTypes";
import {SCENE_NAMES} from "../../constants/game";

const {GAME_SCENE, MENU_SCENE} = SCENE_NAMES;

export function goToGameScene() {
  return {type: CHANGE_SCENE, payload: GAME_SCENE};
}

export function goToMenuScene() {
  return {type: CHANGE_SCENE, payload: MENU_SCENE};
}