import React, { useEffect, useRef } from 'react';
import { getKenoType } from '../../../helpers/game';
import { connect } from 'react-redux';
import { getSecondTillDraw } from '../../../redux/actions/dataActions';
import { resetIsFetching, setIsFetching } from '../../../redux/actions/isFetchingActions';
import LoaderTimer from '../LoaderTimer';
import { useSelector } from 'react-redux';
import { SCENE_NAMES } from '../../../constants/game';
import { getBeforeAnimationTime, getCorrectTimeTillDraw } from '../../../helpers/general';

const Loader = ({
  isFetching,
  getSecondTillDraw,
  isRaffleActive,
}) => {
  const activeScene = useSelector(state => state.activeScene);
  const { GAME_SCENE } = SCENE_NAMES;
  const refLoading = useRef(null);
  const refDuration = useRef(null);
  const gameType = getKenoType();
  const currentSecond = gameType === 1 ? 59 : 150;

  useEffect(() => {
    if(activeScene === GAME_SCENE){
      (async () => {
        const atTheEndOfAnimation = {};
        atTheEndOfAnimation.promise = new Promise((resolve, reject) => {
          atTheEndOfAnimation.resolve = resolve;
        })
        const now = Date.now();
        const initialSeconds = await getSecondTillDraw(gameType);
        const inheritanceWidth = (currentSecond - initialSeconds) / currentSecond * 100;
        const animation = () => {
         const startAnimation = Date.now();
         const timer = startAnimation - now;
         const currentWidth = inheritanceWidth + ( timer / 10) * (gameType === 1 ? 0.01694915 : 0.006666667);
         const currentTime = initialSeconds -  +( timer / 1000 ).toFixed();
         if(refLoading.current) {
          refLoading.current.style.width = currentWidth + '%';
          refDuration.current.innerHTML = getCorrectTimeTillDraw(currentTime);
         };
         if(currentTime > currentSecond){
          refDuration.current.innerHTML = getBeforeAnimationTime(gameType);
         };
         if(currentTime < 0){
          atTheEndOfAnimation.resolve();
         };
         if(currentTime >= 0){
          requestAnimationFrame(animation);
         };
        };
        requestAnimationFrame(animation);
        atTheEndOfAnimation.promise.then(() => {
          if(refLoading.current && refDuration.current) {
            refLoading.current.style.width = 0;
            refDuration.current.innerHTML = getBeforeAnimationTime(gameType);
          };
        });
      })();
    };
    
  }, [ activeScene, isRaffleActive ]);

  return (
    <>
      <div className="loader">
        <div className="loader__background">
          {!isFetching && (
          <div
            ref={refLoading}
            className="loader__percent"
          />
        )}
        </div>
      </div>
      {activeScene === GAME_SCENE && <LoaderTimer refDuration={refDuration} />}
    </>
    
  );
};

const mapStateToProps = ({ secTillDrawReducer, isFetching, isRaffleActive }) => ({
  secTillDraw: secTillDrawReducer,
  isFetching,
  isRaffleActive,
});

const mapDispatchToProps = {
  setIsFetching,
  resetIsFetching,
  getSecondTillDraw,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
