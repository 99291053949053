import {SHOW_POPUP} from "../actionTypes";
import {createAction} from "../../helpers/general";
import {POPUP_TYPES} from "../actionTypes";

const {LEADERBOARD_SHOW_POPUP, LEADERBOARD_HIDE_POPUP} = POPUP_TYPES;

export function changePopup() {
    return {type: SHOW_POPUP};
}

export const showLeaderboardPopup = createAction(LEADERBOARD_SHOW_POPUP);
export const hideLeaderboardPopup = createAction(LEADERBOARD_HIDE_POPUP);
