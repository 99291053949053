import enKeno from './jsons/enKeno.json';
import swKeno from './jsons/swKeno.json';
import enNumbers from './jsons/enNumbers.json';
import swNumbers from './jsons/swNumbers.json';

const languages = {
  en: {
    keno: enKeno,
    numbers: enNumbers,
  },
  sw: {
    keno: swKeno,
    numbers: swNumbers,
  },
};

const getActiveLanguage = (lang, isNumbers) => {
  const type = isNumbers ? 'numbers' : 'keno';
  const language = languages[lang] || languages['en'];
  return language[type];
};

export default getActiveLanguage;
