import React from 'react';
import { connect } from 'react-redux';
import { setKenoType } from '../../redux/actions/kenoTypeActions';
import { useLanguageData } from '../../Context/LanguageContext';

const DurationButton = ({ duration, kenoType, setDuration }) => {
  const languageData = useLanguageData();
  return (
    <label className="game-duration__label">
      <input
        className="game-duration__input"
        type="radio"
        name="game-duration"
        defaultChecked={duration === kenoType}
      />
      <span
        onClick={() => setDuration(duration)}
        className='game-duration__text'>
        {duration} {languageData.minutes}.
      </span>
    </label>
  );
};

const mapStateToProps = ({ kenoType }) => ({
  kenoType,
});

const mapDispatchToProps = {
  setDuration: setKenoType,
};

export default connect(mapStateToProps, mapDispatchToProps)(DurationButton);
