import React, { useEffect } from 'react';
import images from '../../assets/images';
import { goToGameScene } from '../../redux/actions/activeSceneActions';
import { getGameType, getGameVersions } from '../../redux/actions/dataActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { GAME_TYPES } from '../../constants/names';
import { setGameType } from '../../redux/actions/userDataActions';

const { KENO, NUMBERS } = GAME_TYPES;

const LoadingScene = ({ getGameVersions, gameVersions, goToGameScene }) => {
  const gameType = useSelector((state) => state.gameType);
  const storeCredentials = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { token } = Object.fromEntries(urlSearchParams.entries());

    localStorage.setItem('token', token);
  };

  useEffect(() => {
    storeCredentials();
    getGameVersions();
  }, []);

  return (
    <main className="loadingScene game-grid--welcome " style={{ flexDirection: 'column' }}>
      <div className="loadingScene__container">
        <div className="loadingScene__logo">
          <div className="loadingScene__text">Powered by</div>
          <img src={images.NrLogo} alt="wrapper keno" className="loadingScene__nr-img" />
        </div>
      </div>
      <div className="loadingScene__loader" style={{ width: '184px', flexGrow: 0 }}>
        <div className="loadingScene__loader-background">
          <div
            className="loadingScene__loader-percent"
            style={{ animation: `loader 1s linear forwards`, width: 0 + '%' }}
          />
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = ({ gameVersionsReducer }) => ({
  gameVersions: gameVersionsReducer,
});

const mapDispatchToProps = {
  goToGameScene,
  getGameVersions,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScene);
