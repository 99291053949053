import {BET_BTN_TYPES} from '../actionTypes';

const {ADD_BTN, ADD_GROUP_BTNS, DELETE_BTN, RESET_BTNS, ADD_RANDOM_BTNS, DELETE_GROUP_BTNS} = BET_BTN_TYPES;

function addBetButton(btn) {
  return {type: ADD_BTN, payload: btn};
}

function addGroupBetButton(groupDigits) {
  return {type: ADD_GROUP_BTNS, payload: groupDigits};
}

function deleteGroupBetButton(remainingBalls) {
  return {type: DELETE_GROUP_BTNS, payload: remainingBalls};
}

function deleteBetButton(btn) {
  return {type: DELETE_BTN, payload: btn};
}

function addRandomBetBtns(btns) {
  return {type: ADD_RANDOM_BTNS, payload: btns};
}

const resetBetButtons = {type: RESET_BTNS};

export {addBetButton, addGroupBetButton, deleteGroupBetButton, deleteBetButton, resetBetButtons, addRandomBetBtns};