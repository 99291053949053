import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import dataReducers from './reducers/dataReducers';
import viewReducers from "./reducers/viewReducers";
import {isDevelopmentStage} from "../helpers/general";

let compose = isDevelopmentStage() ? composeWithDevTools : (param) => param;

const store = createStore(combineReducers({
  ...viewReducers,
  ...dataReducers
}), compose(applyMiddleware(thunkMiddleware)));

export const getStoreState = store.getState;
export default store;
window.getState = () => store.getState()