import {DATA_TYPES, CHANGE_LANGUAGE, SET_GAME_TYPE} from '../actionTypes';

export const setUserData = (value) => {
    return {type: DATA_TYPES.SET_USER_DATA, value};
};

export const setLanguage = (value) => {
    return {type: CHANGE_LANGUAGE, value}
}

export const setGameType = (value) => ({type: SET_GAME_TYPE, value})