import { useState, useRef, useCallback, useEffect } from 'react';

export function useTimer(initialValue, msInterval, isReversed) {
  const [timerCount, setTimerCount] = useState(initialValue);
  const intervalRef = useRef(null);

  useEffect(() => {
    setTimerCount(initialValue);
  }, [initialValue]);

  const startTimer = useCallback(() => {
    if (intervalRef.current !== null) {
      return;
    }

    intervalRef.current = setInterval(() => {
      if (isReversed) {
        setTimerCount((prev) => prev - 1);
      } else {
        setTimerCount((prev) => prev + 1);
      }
    }, msInterval);
  }, [initialValue]);

  const stopTimer = useCallback(() => {
    if (intervalRef.current === null) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, [initialValue]);

  const resetTimer = useCallback(() => {
    setTimerCount(initialValue);
  }, [initialValue]);

  return { timerCount, startTimer, stopTimer, resetTimer, setTimerCount };
}
