import React from 'react';

function LoaderTimer({ refDuration }) {


  return (
    <div  className="loader__panel">
      <span ref={refDuration}></span>
    </div>
  );
}

export default LoaderTimer;
