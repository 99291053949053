import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import UserHistoryDrawContainer from './UserHistoryDrawContainer/UserHistoryDrawContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import dataParser from '../../../../helpers/dataParser';
import { getToken, url } from '../../../../constants/api';
import axios from 'axios';
import { getKenoType } from '../../../../helpers/game';

const UserHistory = ({ userHistoryTickets, scrollableTarget, isRaffleActive, drawInfo }) => {
  const [tickets, setTickets] = useState(userHistoryTickets);
  const [newTicketArr, setNewTicketArr] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const ticketsArr = Object.entries(tickets).reverse();

  const filterTiket = isRaffleActive && +ticketsArr[0][0] === drawInfo - 1;

  const countReducer = useSelector(state => state.countReducer)
    useEffect(() => {
    const filteredTikets = filterTiket
      ? ticketsArr.filter((item, index, arr) => item !== arr[0])
      : ticketsArr;
    setNewTicketArr(filteredTikets);
  }, [ tickets]);
  useEffect(() => {
    axios
      .get(`${url}/get-draw-history/`, {
        params: {
          token: getToken(),
          page: 0,
          duration: getKenoType(),
        },
      })
      .then((response) => {
        const gameData = response.data.data;
        const parsedData = dataParser(gameData);
        const { userHistoryTickets } = parsedData;
        setHasMore(!!gameData.userStakes.length);
        setTickets(userHistoryTickets);
        setPage(0);
      });
  }, [countReducer]);

  return (
    <li className="game-grid__info-container active">
      <ul className="my-history__list">
        <InfiniteScroll
          dataLength={ticketsArr.length}
          next={getTickets}
          hasMore={hasMore}
          scrollableTarget={scrollableTarget}
          loader={loader}>
          {newTicketArr.map((userStakes) => {
            const [drawId, tickets] = userStakes;
            return <UserHistoryDrawContainer key={drawId} tickets={tickets} drawId={drawId} />;
          })}
        </InfiniteScroll>
      </ul>
    </li>
  );

  function getTickets() {
    const nextPage = page + 1;
    axios
      .get(`${url}/get-draw-history/`, {
        params: {
          token: getToken(),
          page: nextPage,
          duration: getKenoType(),
        },
      })
      .then((response) => {
        setPage(nextPage);
        const gameData = response.data.data;
        const parsedData = dataParser(gameData);
        const { userHistoryTickets } = parsedData;
        setHasMore(!!gameData.userStakes.length);
        setTickets((prevState) => ({ ...prevState, ...userHistoryTickets }));
      });
  }
};

const loader = (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className="customLoader" />
  </div>
);

const mapStateToProps = ({ userHistoryReducer, isRaffleActive, drawInfoReducer }) => ({
  userHistoryTickets: userHistoryReducer,
  isRaffleActive,
  drawInfo: drawInfoReducer.drawId,
});

export default connect(mapStateToProps)(UserHistory);
