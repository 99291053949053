import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import images from '../../assets/images';
import { useLanguageData } from '../../Context/LanguageContext';
import { toggleSound } from '../../redux/actions/dataActions';

function SoundButton() {
  const dispatch = useDispatch();
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);
  const languageData = useLanguageData();

  const onSoundButtonClick = () => {
    dispatch(toggleSound());
    localStorage.setItem('sound', JSON.stringify(!isSoundActive));
  };

  return (
    <li className="game-settings__item">
      <button onClick={() => onSoundButtonClick()} className="game-settings__button">
        <img
          src={isSoundActive ? images.sound : images.sound_disable}
          alt="sound icon"
          width="15"
          height="15"
          className="game-settings__image"
        />
        <span className="game-sound__text">
          {isSoundActive ? languageData.soundOn : languageData.soundOff}
        </span>
      </button>
    </li>
  );
}

export default SoundButton;
