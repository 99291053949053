import React, { useMemo, useState } from 'react';
import StatisticsNumber from './StatisticsNumber';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../../../Context/LanguageContext';
import { setFrequencyTab } from '../../../../redux/actions/dataActions';

function Statistics() {
  const dispatch = useDispatch();
  const languageData = useLanguageData();
  const { activeTab, balls } = useSelector((state) => state.ballFrequencyReducer);
  const drawsTab = Object.keys(balls);

  const changeDrawsTab = (item) => {
    dispatch(setFrequencyTab(item));
  };

  const currentBalls = useMemo(() => {
    const currentBalls = balls[activeTab];

    const maxHot = Math.max(...Object.values(currentBalls.hotBalls));
    const minCold = Math.min(...Object.values(currentBalls.coldBalls));

    if (!currentBalls) return [];
    const coldBalls = Object.entries(currentBalls.coldBalls).reduce((acc, [digit, frequency]) => {
      acc.push({
        digit,
        frequency,
        ballTemperature: 'coldBalls',
        maxHotCold: minCold,
      });
      return acc;
    }, []);

    const hotBalls = Object.entries(currentBalls.hotBalls).reduce((acc, [digit, frequency]) => {
      acc.push({
        digit,
        frequency,
        ballTemperature: 'hotBalls',
        maxHotCold: maxHot,
      });

      return acc;
    }, []);

    return [...hotBalls, ...coldBalls];
  });

  return (
    <li className="game-grid__info-container active">
      <div className="statistics">
        <ul className="statistics__but">
          {drawsTab.map((item) => (
            <button
              key={item}
              onClick={() => changeDrawsTab(item)}
              className={`statistics__but ${activeTab === item ? 'active' : ''} `}>
              {item} {languageData['Draws']}
            </button>
          ))}
        </ul>
        <div className="statistics__sorts">
          <div className="statistics__sort statistics__sort--blue">{languageData.notOften}</div>
          <div className="statistics__sort statistics__sort--red">{languageData.often}</div>
        </div>
        <ul className="statistics__list">
          {currentBalls.map((number) => (
            <StatisticsNumber
              key={number.digit}
              number={number.digit}
              frequency={number.frequency}
              temperature={number.ballTemperature}
              maxHotCold={number.maxHotCold}
            />
          ))}
        </ul>
      </div>
    </li>
  );
}

const mapStateToProps = ({ ballFrequencyReducer }) => ({
  statisticsData: ballFrequencyReducer,
});

export default connect(mapStateToProps)(Statistics);
