import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { addRandomBetBtns } from '../../../redux/actions/betBtnActions';
import { generateRandomDigits } from '../../../helpers/game';
import { setIsBetCanceled } from '../../../redux/actions/optionActions';
import { useSoundConstext } from '../../../Context/SoundContext';

const RandomDigit = ({ digit, addRandomBetBtns }) => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const { isBetCanceled } = useSelector((state) => state.options);
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);

  const handleRandomChoice = () => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    addRandomBetBtns(generateRandomDigits(digit));
  };

  return (
    <li className="random-button-container">
      <button className="random-button" value={digit} onClick={handleRandomChoice}>
        {digit}
      </button>
    </li>
  );
};

const mapDispatchToProps = {
  addRandomBetBtns,
};

export default connect(null, mapDispatchToProps)(RandomDigit);
